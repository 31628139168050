import React from 'react'
// import banner2 from '../assets/banner2.jpg'
// import bannerc from '../assets/bannerc.jpg'
// import Navbar from '../components/Navbar/index';
// import logo from '../assets/1x/icone.png'
// import logo1 from '../assets/1x/icone.png'
import Presentation from '../components/acceuil/Presentation';


import { A11y, Autoplay, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// import 'swiper/css/effect-fade';
import Objectif from '../components/acceuil/Objectif';
import Services from '../components/acceuil/Services';
import Contact from '../components/acceuil/Contact';
// import Footer from './../components/Footer/index';
// import Partenaires from '../components/acceuil/Partenaires';
import Avantage from '../components/acceuil/Avantage';
import Mission from '../components/acceuil/Mission';
// import Navsbar from '../components/Navbar/Navsbar';
import cteq from '../assets/1x/cteq.webp'
import quebec from '../assets/1x/quebec.png'
import pn from '../assets/1x/pn.svg'
import chambers from '../assets/1x/chambers.avif'
import fga from '../assets/1x/fga.svg'
import fit from '../assets/fit.png'
import hec from '../assets/hec.svg'
import ifpf from '../assets/ifpf.png'
import event from '../assets/2149304766.jpg'
import { NavLink } from 'react-router-dom';
import activite1 from '../assets/2149006894.jpg'
// import imag from '../assets/apropos/240619_22H03-40_8569-HRres_10po@300dpi-sRgb.jpg'
import imag1 from '../assets/apropos/240619_20H48-49_8508-HRres_10po@300dpi-sRgb.jpg'
import imag2 from '../assets/apropos/240619_20H48-49_8508-HRres_10po@300dpi-sRgb.jpg'
import imag3 from '../assets/apropos/240619_19H57-54_8404-HRres_10po@300dpi-sRgb.jpg'
import imag4 from '../assets/apropos/240619_22H03-40_8569-HRres_10po@300dpi-sRgb.jpg'
import imag5 from '../assets/apropos/240619_22H04-11_8573-HRres_10po@300dpi-sRgb.jpg'
import imag6 from '../assets/apropos/240619_22H07-03_8582-HRres_10po@300dpi-sRgb.jpg'
// import imag4 from '../assets/apropos/240619_22H03-40_8569-HRres_10po@300dpi-sRgb.jpg'
// import { SwiperSlide } from 'swiper/react';



export default function Home() {
    const data = [
        {
            link: 'https://ctequebec.com/',
            image: cteq,
        },
        {
            link: 'https://www.quebecinternational.ca/',
            image: quebec,
        },
        {
            link: 'https://www.printempsnumerique.ca/',
            image: pn,
        },
        {
            link: 'https://canadaafrica.ca/',
            image: chambers,
        },
        {
            link: 'https://fedefranco.ca/',
            image: fga,
        },
        {
            link: 'http://franchise-it-tech.com/',
            image: fit,
        },
        {
            link: 'https://www.hec.ca/',
            image: hec,
        },
        {
            link: 'http://franchise-it-tech.com/',
            image: ifpf,
        }
    ]

    const datas = [
        {
            image:imag1,
            jours:14,
            mois:'OCT',
            titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
            descrip: "Organisation de webinaires sur des sujets clés tels que l'entrée sur le marché, la culture d'affaires, la législation locale et les pratiques commerciales durables."
        },
        {
            image:imag2,
            jours:25,
            mois:'OCT',
            titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
            descrip: "Organisation de missions commerciales régulières, tant au Canada qu’en Afrique, pour permettre aux entreprises de rencontrer des partenaires potentiels et de découvrir les marchés locaux."
        },
        {
            image:imag3,
            jours:'05',
            mois:'OCT',
            titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
            descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'
        }
    ]

    const datac = [
        {
            title: 'Plateforme d’Échange Commercial',
            descrip: 'Création d\'une plateforme numérique qui met en relation les entreprises canadiennes et africaines. Cette ...',
            mois: 'OCT',
            jrs:'28'
        },
        {
            title: 'Webinaires et Ateliers de Formation',
            descrip: 'Organisation de webinaires sur des sujets clés tels que l\'entrée sur le marché, la culture d\'affaires, la ...',
            mois: 'NOV',
            jrs: '30'
        },
        {
            title: 'Missions Commerciales',
            descrip: 'Organisation de missions commerciales régulières, tant au Canada qu’en Afrique, pour permettre ...',
            mois: 'NOV',
            jrs: '17'
        }
    ]

    const dataImage = [
        imag1,imag2,imag3,imag4,imag5,imag6
    ]
    // const Banners = (props) => {
    //     const { image, descrip } = props
    //     return (
    //         <div className='relative xl:h-[100vh] max-lg:h-[85vh] max-md:h-[75vh] max-sm:h-[65vh])] bg-opacity-45 bg-primary'>
    //             {/* <div className='flex items-center object-cover w-full h-full'>
    //                 <div className='w-1/2 text-white max-md:w-full'>
                        
    //                 </div>
    //                 <div className='w-1/2 max-md:hidden radius'>
    //                     <img src={image} className=' h-[450px] w-[95%] object-cover object-center'  alt='' />
    //                 </div>
    //             </div> */}
    //             <img src={image} className='object-cover w-full h-full' alt='' />
    //             <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-20 max-md:bg-opacity-50'></div>
    //             <div className='absolute w-[100%] top-[50%] text-start -translate-y-[50%] bg-red z-[200] text-white flex flex-col px-[4%]'>
    //                 {/* <h1 className='text-deco'>{marque}</h1> */}
    //                 <div className='flex flex-col w-1/2 max-md:w-full max-md:items-center max-md:justify-center'>
    //                     {/* <h1 className='mb-1 mt-1 text-[2rem] font-bold'>{titre}</h1> */}
    //                     <span className='text-[3.2rem] max-lg:text-[2rem] max-md:text-[1.6rem] max-md:text-center font-bold'>{descrip}</span>
    //                 </div>
    //                 {/* <Link className='font-medium text-[15px]' href={'#'}>READ MORE</Link> */}
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <>
        {/* <main> */}
            {/* <header className='relative'>
                <Swiper
                    modules={[Scrollbar, A11y, Autoplay, EffectFade]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={0}
                    slidesPerView={1}
                    effect='fade'
                >
                    <SwiperSlide className=''>
                        <Banners
                            titre="L'expertise au service de votre Cybersécurité"
                            image={banner2}
                            descrip="Nous sommes un pont vers l'afrique et une passerelle vers le Canada"
                        />
                    </SwiperSlide>           
                </Swiper>
                <div className='absolute z-[100] top-0 w-full'>
                    <Navbar logo={logo} logos={logo1} />
                </div>
                <Navsbar />
            </header> */}
            <div className='h-[calc(100vh-185px)] relative flex items-center w-full'>
                <img src={event} alt='#' className='absolute z-1 w-full object-cover object-right h-full' />
                <div className='w-full h-full absolute bannerce z-2'></div>
                <div className='px-[5%] z-10 text-white w-1/2 flex flex-col gap-4 max-md:w-full max-md:text-center'>
                    <h6 className='text-[0.75rem] text-opacity-60 font-semibold'>ÈVÈNEMENT EN PRESENTIEL</h6>
                    <p className='text-[3.1rem] max-md:text-[1.6rem] leading-tight font-bold'>Nous sommes un pont vers l'Afrique et une passerelle vers le Canada</p>
                    <span className='font-bold text-[.8rem]'>MIDI - CONFERENCE</span>
                </div>
            </div>
            <div className='bg-[#062338FF] px-[5%] py-5'>
                <div className='flex items-center gap-3'>
                <Swiper
                    modules={[Scrollbar, A11y, Autoplay]}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={15}
                    // slidesPerView={4}
                    breakpoints={{ 
                        300: {
                            slidesPerView:1
                        },
                        700: {
                            slidesPerView: 2
                        },
                        1000: {
                            slidesPerView: 4
                        }
                     }}
                    // effect='fade'
                >
                        {data?.map((x) =>
                        <SwiperSlide className='bg-gray-100'>
                            <NavLink className='bg-gray-100 py-1 px-2 flex items-center justify-center rounded-sm' to={x.link}>
                                <img className=" h-[65px]" src={x.image} alt='' />
                            </NavLink>
                        </SwiperSlide>
                        )}
                </Swiper>
                </div>
            </div>
            <div className="flex flex-col items-center sm:items-start bg-red">
                <Presentation />
                <Mission />
                <Avantage />
                <div className='px-[5%] py-md max-md:py-12'>
                        <div className="w-full flex max-md:flex-col gap-[3.8rem]">
                            <div className='w-[70%] max-md:w-full'>
                                <h1 className='text-primary text-[2rem] border-b-[1px] border-primary pb-1 max-md:text-[2rem] font-semibold'>Evènements</h1>
                                <div className='flex flex-col'>
                                    {datas?.map((x, index) =>
                                        <div key={index} className='relative w-full flex max-md:flex-col items-center py-5 flex-row-reverse gap-5 overflow-hidden transition-all duration-500 border-b-[1px] border-primary last-of-type:border-none cursor-pointer '>
                                            <div className='w-[40%] relative max-md:w-full h-[190px]' style={{ backgroundImage: `url(${x.image})`,backgroundSize:'cover' }}>
                                                <div className='absolute top-[7%] z-[100] flex flex-col items-center justify-center left-[5%] w-[70px] h-[70px] bg-[rgba(255,255,255,.9)] backdrop-blur'>
                                                    <h1 className='font-extrabold m-0 text-[1.45rem]'>{x.jours}</h1>
                                                    <span className='text-[.8rem] font-semibold'>{x.mois}</span>
                                                </div>
                                                {/* <img className='absolute w-full h-full object-cover' src={activite1} alt='#' /> */}
                                            </div>
                                            <div className='flex flex-col gap-3 w-[60%] max-md:w-full'>
                                                <h1 className='text-[1.5rem] max-md:text-[1.2rem] max-md:text-center font-bold text-primary'>{x.titre}</h1>
                                                <span className='text-[1rem] max-md:text-[.95rem] font-light'>{x.descrip}</span>
                                                {/* <span className='text-[.75rem] text-gray-500 font-semibold'>- 21 Sept 2024</span> */}
                                            </div>
                                        </div>
                                    )}

                                </div>
                                
                            </div>
                            <div className='w-[30%] max-md:w-full flex flex-col gap-8'>
                                <div className='flex flex-col gap-3'>
                                    <h1 className='text-primary text-[2rem] border-b-[1px] border-primary pb-1 max-md:text-[2rem] font-semibold'>Activités</h1>
                                    {datac?.map((x,index)=>
                                        <div key={index}  className='bg-gray-200 flex px-3 py-3 max-md:items-center gap-4'>
                                        <div className='flex flex-col items-center justify-center px-7 w-[55px] py-2 h-[60px] bg-[rgba(255,255,255,1)]'>
                                            <h1 className='font-extrabold m-0 text-[1.15rem]'>{x.jrs}</h1>
                                            <span className='text-[.8rem] font-semibold'>{x.mois}</span>
                                        </div>
                                        <div className='flex flex-col'>
                                            <h1 className='text-[.9rem] max-md:text-[1.1rem] font-semibold text-primary'>{x.title}</h1>
                                            <span className="text-[.8rem] font-medium text-opacity-75 text-justify">{x.descrip}</span>
                                        </div>
                                    </div>
                                    )}
                                </div>
                                <div className='flex flex-col gap-3'>
                                    <h1 className='text-primary text-[1.9rem] border-b-[1px] border-primary pb-1 max-md:text-[2rem] font-semibold'>Galleries</h1>
                                    <div className='grid grid-cols-3 gap-1 flex max-md:items-center'>
                                        {dataImage?.map((x,index)=>
                                            <div key={index}  className='relative h-[110px] max-md:h-[130px]'>
                                                <img src={x} className="absolute w-full h-full object-cover" />
                                            </div>
                                        )}
                                        </div>
                                </div>
                            </div>
                        </div>
                </div>
                <Services />
                <Objectif />
                {/* <Partenaires /> */}
                {/* <Faq /> */}
                <Contact />
                
            </div>
        {/* </main> */}
        {/* <Footer /> */}
        </>
    );
}
