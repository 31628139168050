import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Banner from '../../components/Banner'
import Annimated from '../../components/Annimated'


export default function LayoutsApropos() {
    const { detail } = useParams()
    const data = [
        'Présentation',
        'Conseil d\'administration',
        'Direction Exécutive',
        'Gallerie',
        'Contacts',
    ]

    return (
        <Annimated>
            <Banner title={'Apropos de nous'} />
            <div className='px-[5%] py-14 gap-10 flex max-md:flex-col w-full'>
                <div className='w-[25%] max-md:w-full bg-gray-200'>
                    <ul className='flex flex-col'>
                        {data?.map((x,index) =>
                            <li key={index} className="border-b-[1px] border-gray-300 last-of-type:border-none"><NavLink className='px-5 service-side py-5 text-[.9rem] font-semibold block' to={`/a/Apropos-de-nous/${x}`}>{x}</NavLink></li>
                        )}
                    </ul>
                </div>
                <div className='w-[75%] max-md:w-full text-[1.1rem]'><Outlet /></div>
            </div>
        </Annimated>
    )
}
