import { Link } from 'react-router-dom'
import React from 'react'
import logo1 from '../../assets/1x/icone.png'
// import badge from '@/assets/badge.png'
import {Facebook02Icon} from 'hugeicons-react'
import {NewTwitterIcon} from 'hugeicons-react'
import {Linkedin02Icon} from 'hugeicons-react'
import {InstagramIcon} from 'hugeicons-react'

function Footer() {
    return (
        <div className='bg-opacity-75 bg-gray-500 text-white py-14 px-[5%]'>
            <div className='grid grid-cols-4 gap-3 max-md:gap-4 pb-12 max-lg:grid-cols-2 max-md:grid-cols-2 max-sm:grid-cols-1'>
                <div className='mt-3'>
                    {/* <Image src={logo1} width={200} heigth={40} className='h-[70px]' alt='' /> */}
                    <Link className='flex items-center gap-2 max-md:gap-1' to="/">
                        <img src={logo1} alt='' width={60} height={60} className='images' id="images" />
                        <div className='flex flex-col font-bold text-start'>
                            <span>CHAMBRE DE COMMERCE</span>
                            <span>CANADA AFRIQUE</span>
                        </div>
                    </Link>
                    {/* <h5 className='m-0 mb-2'>Pour nous suivre:</h5> */}
                    <div className='flex items-center gap-2 mt-5'>
                        <Link to='#' className='w-[30px] h-[30px]  flex justify-center items-center transition-all hover:scale-110'><Facebook02Icon size={20}/></Link>
                        <Link to='#' className='w-[30px] h-[30px]  flex justify-center items-center transition-all hover:scale-110'><NewTwitterIcon size={20} /></Link>
                        <Link to='#' className='w-[30px] h-[30px]  flex justify-center items-center transition-all hover:scale-110'><Linkedin02Icon size={20} /></Link>
                        <Link to='#' className='w-[30px] h-[30px] flex justify-center items-center transition-all hover:scale-110'><InstagramIcon size={20} /></Link>
                    </div>
                </div>
                <div className='mt-3'>
                    <ul className='text-[.9rem] font-normal flex flex-col gap-3'>
                        <li><Link to={'/Devenir_membre'}>Devenir membre</Link></li>
                        <li><Link to={'/Repertoire_membre'}>Répertoire des membres</Link></li>
                        <li><Link to={'/about'}>A propos de nous</Link></li>
                        <li><Link to={'/Contact'}>Contact</Link></li>
                        <li><Link to={'/Pub'}>Publicité et partenariat</Link></li>
                        <li><Link to={'/Actualite'}>Actualités</Link></li>
                    </ul>
                </div>
                <div className='flex flex-col mt-3'>
                    <h5 className='m-0 mb-2 text-[1.1rem] font-medium'>Devenir Membre:</h5>
                    <ul className='text-[.9rem] font-normal flex flex-col gap-3'>
                        <li><Link to={'/pourquoi_devenir_membre'}>Pourquoi devenir membre</Link></li>
                        <li><Link to={'/Avantage_membre'}>Avantages aux membres</Link></li>
                        <li><Link to={'Devenir_benevole'}>Devenir bénévole</Link></li>
                        <li><Link to={'/nouvelle_des_membres'}>Nouvelles des membres</Link></li>
                        <li><Link to={'/Pub'}>Publicité et partenariat</Link></li>
                        <li><Link to={'/Repertoire_membre'}>Repertoire des membres</Link></li>
                        <li><Link to={'/Outils'}>Outils et ressources</Link></li>
                    </ul>
                </div>
                
                <div className='flex flex-col mt-3'>
                    <h5 className='m-0 mb-2 text-[1.1rem] font-medium'>Notre info lettre</h5>
                    <span className='text-[.9rem] font-normal'>Pour rester au coeur de l’activité économique de la métropole</span>
                    <div className='w-full flex my-3 items-center overflow-hidden rounded-sm bg-white py-1 px-1'>
                        <input className='px-4 py-1 text-sm rounded-sm w-[100%] outline-0 border-0 placeh' type='text' placeholder='ADDRESSE COURIEL'></input>
                        <button className='flex items-center justify-center py-1 text-sm gap-2 px-5 rounded-sm bg-secondary'>
                            S&apos;inscrire
                        </button>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-between gap-3 max-md:flex-col max-md:justify-center'>
                <span className='text-[.85rem] max-md:text-[.82rem]'>&copy;&nbsp;2024 - Chambre de Commerce Canada Afrique</span>
                <div className='flex items-center gap-4 text-[12px]'>
                    <Link className='underline' to={'/policy'}>Politique de confidentialité</Link>
                    <Link className='underline' to={'/plan'}>Plan du site</Link>
                </div>
            </div>
        </div>
    )
}

export default Footer