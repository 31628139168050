import React from 'react'

export default function TopBar() {
  return (
    <div className='bg-tiers max-lg:hidden z-[150] flex w-full justify-end'>
        <div className='flex gap-1'>
             <div className='cursor-pointer text-[.7rem] px-4 py-2.5 border-x-2 border-gray-300'>Connection</div>
             <div className='cursor-pointer text-[.7rem] px-4 py-2.5'>Français</div>
        </div>
    </div>
  )
}
