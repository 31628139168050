import React from 'react'

import { A11y, Autoplay, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import image from '../../assets/burkina.png'
import images from '../../assets/ouagadougou.jpg'
import { NavLink } from 'react-router-dom';
import Annimated from '../../components/Annimated';


export default function BureauxAfrique() {

    const datas = [
        {
            imag: images,
            ville: 'Ouagadougou',
            descrip:'En tant que capitale et plus grande ville du Burkina Faso, Ouagadougou constitue le centre administratif, économique et culturel du pays. Elle abrite des institutions gouvernementales, des organisations internationales et un secteur privé en pleine croissance.'
        },
        {
            imag: images,
            ville: 'Ouagadougou',
            descrip: 'En tant que capitale et plus grande ville du Burkina Faso, Ouagadougou constitue le centre administratif, économique et culturel du pays. Elle abrite des institutions gouvernementales, des organisations internationales et un secteur privé en pleine croissance.'
        },
        {
            imag: images,
            ville: 'Ouagadougou',
            descrip: 'En tant que capitale et plus grande ville du Burkina Faso, Ouagadougou constitue le centre administratif, économique et culturel du pays. Elle abrite des institutions gouvernementales, des organisations internationales et un secteur privé en pleine croissance.'
        },
        {
            imag: images,
            ville: 'Ouagadougou',
            descrip: 'En tant que capitale et plus grande ville du Burkina Faso, Ouagadougou constitue le centre administratif, économique et culturel du pays. Elle abrite des institutions gouvernementales, des organisations internationales et un secteur privé en pleine croissance.'
        }
    ]

    const SlideItems = ({x}) => {
        return (
            <NavLink className='text-black w-full'>
                <img src={x.imag} className='w-full h-[190px]' alt='' />
                <div className='flex flex-col p-4 gap-2'>
                    <h1 className='text-center text-md font-bold'>{x.ville}</h1>
                    <p className='text-justify text-[.85rem]'>{x.descrip}</p>
                </div>
            </NavLink>
        )
    }
    return (
        <Annimated className='flex flex-col gap-10'>
        <div className='text-[1.1rem] text-justify'>
            <img src={image} className='float-right object-contain w-[250px] h-[250px]' alt='' />
                <h1 className='font-bold text-[1.5rem]'>M. Soulamane KONKOBO</h1>
            <p>
            CCCA-Burkina est dirigé par M. Soulamane KONKOBO, PDG de SUMMUM CONSULTING GROUP, également appelé « Le Pays des Hommes Honnêtes », est un pays enclavé situé en Afrique de l’Ouest. Ce surnom met en valeur les valeurs culturelles du pays et l’importance accordée à l’intégrité et à la conduite morale. Le Burkina Faso est également reconnu pour sa scène artistique et culturelle dynamique. La musique et la danse traditionnelles, comme les rythmes entraînants du tambour djembé et les mouvements gracieux des troupes de danse traditionnelle, font partie intégrante de la culture burkinabè. Le pays a produit des musiciens, cinéastes et artistes talentueux qui utilisent leurs expressions créatives pour refléter l’esprit et les aspirations du peuple burkinabè.
            <br></br>
            <br></br>
            Ces dernières années, le Burkina Faso a été confronté à des défis liés à la sécurité et au développement socio-économique. Cependant, le peuple burkinabè continue de faire preuve de résilience, de détermination et d’engagement à construire un avenir meilleur pour son pays.
            </p>
        </div>
        <div>
            <h1 className='font-bold text-center text-[1.5rem] mb-3'>Avantages</h1>
            <p className='text-center text-[1.1rem]'>Le Burkina Faso est un pays doté de potentiels économiques diversifiés et ses villes jouent un rôle important dans le moteur de la croissance économique et du développement. Voici quelques-unes des villes clés du Burkina Faso et leurs potentiels économiques :</p>
                <div className='py-5'>
                    <div className='flex items-center gap-3'>
                        <Swiper
                            modules={[Scrollbar, A11y, Autoplay]}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            spaceBetween={15}
                            // slidesPerView={4}
                            breakpoints={{
                                300: {
                                    slidesPerView: 1
                                },
                                700: {
                                    slidesPerView: 2
                                },
                                1000: {
                                    slidesPerView: 3
                                }
                            }}
                        // effect='fade'
                        >
                            {datas?.map((y,index) =>
                                <SwiperSlide key={index} className=''>
                                    <SlideItems x={y} />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
                <span className='text-[1.1rem] text-justify'>Ces villes, ainsi que d’autres au Burkina Faso, offrent des potentiels économiques uniques en fonction de leur situation géographique, des ressources disponibles et des industries locales. Le développement de ces potentiels nécessite des investissements dans les infrastructures, l’éducation et le développement des compétences, l’accès au financement et la promotion de l’entrepreneuriat et de l’innovation.</span>
        </div>
        </Annimated>

    )
}
