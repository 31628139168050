import React from 'react'
import { A11y, Scrollbar, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import actu from '../../assets/actualite.png'
import jambo from '../../assets/jambotv.PNG'
import actual from '../../assets/actual1.jpg'
import actual2 from '../../assets/actu2.avif'
import actual3 from '../../assets/actual2.jpg'
import { NavLink } from 'react-router-dom';

function Services() {

  const data = [
    {
      image: jambo,
      libelle: 'Interview du Président de la Chambre de Commerce Canada Afrique sur le plateau de Jambo TV à Canal2',
      date:'23 juillet 2024'
    },
    {
      image: actual,
      libelle: 'Exode des cerveaux : l’Afrique se vide au profit du Canada',
      date: '09 Octobre 2024',
      link:'https://francopresse.ca/actualite/2024/10/07/exode-des-cerveaux-lafrique-se-vide-au-profit-du-canada/'
    },
    {
      image: actual2,
      libelle: 'Québec cesse de recruter des infirmières en Afrique pour des raisons « éthiques »',
      date: '02 Octobre 2024',
      link:'https://ici.radio-canada.ca/nouvelle/2108988/recruter-infirmieres-afrique-ethique-caq-maroc-tunisie'
    },
    {
      image: actual3,
      libelle: 'Exploiter les possibilités pour l’hydrogène vert au Canada atlantique',
      date: '10 Septembre 2024',
      link:'https://www.investircanada.ca/news/exploiter-possibilites-pour-lhydrogene'
    }
  ]

  const SlideItems = ({ item }) => {
    return (
      <NavLink to={item.link} target='_blank' className="flex relative flex-col items-center justify-center gap-2 bg-white overflow-hidden">
        <div className='relative w-[100%] h-[250px]'>
          <img src={item.image} className='absolute object-cover w-full h-full' alt='~#' />
        </div>
        <div className='flex flex-col justify-start gap-3 px-5 py-2 pb-2 h-[140px] max-lg:h-[160px] max-md:h-[130px]'>
          <h1 className='font-bold text-primary text-[1.15rem] overflow-hidden text-ellipsis max-md:text-[1.05rem]'>{item.libelle}</h1>
          <span className='font-bold absolute bottom-4 uppercase text-gray-500 text-[.8rem] max-md:text-[.8rem]'>{item.date}</span>
        </div>
      </NavLink>
    )
  }
  return (
    <div className=' bg-secondary px-[5%] py-14 w-full'>
      <h1 className='text-white text-[2.1rem] max-md:text-[2rem] mb-4 font-semibold'>Actualités</h1>
      <div className=''>
        <Swiper
          modules={[Scrollbar, Autoplay, A11y]}
          autoplay={{
            delay: 8000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 15
            },
            730: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30
            },
          }}
          className="mySwiper"
          //   effect='scroll'
          //   fadeEffect={{ crossFade: true }}
          //   autoplay={{ waitForTransition: true, stopOnLastSlide: false, delay: 1500, pauseOnMouseEnter: true }}
          scrollbar={{ draggable: true, }}
        >
          {data?.map((x, index) =>
            <SwiperSlide key={index} virtualIndex={index} className=''>
              <SlideItems item={x} />
            </SwiperSlide>
          )}
        </Swiper> 
      </div>
    </div>
  )
}

export default Services