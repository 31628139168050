import React from 'react'
import { useParams } from 'react-router-dom'

export default function ServiceDetails() {
    const {nameservice}=useParams()
    const datas = [
        {
            title: 'Entrepreunariat',
            para: [
                {
                    p: 'La CCCA offre un service complet en matière de démarrage d’entreprise au Canada. À travers son réseau d’experts en entrepreneuriat au Canada, les membres de la CCCA peuvent solliciter nos services afin de les accompagner à démarrer et à réaliser leurs entreprises au Canada ou dans un pays d’Afrique où elle est représentée.'
                },
                {
                    p: 'La CCCA s’appuie sur des partenariats solides ainsi que sur des organismes d’accompagnement en entrepreneuriat au Canada.Ainsi, nous pouvons suggérer des idées d’entreprises innovantes, faire un accompagnement dans la rédaction du projet entrepreneurial, le plan d’affaires ainsi que la planification stratégique, et également le plan de communication marketing.'
                },
                {
                    p: 'En outre, le service d’appui à l’accompagnement de la CCCA est structuré de son incubateur intégré qui offre un environnement complet et des outils clés pour démarrer une entreprise, ouvrir une filiale ou réactiver une entreprise dormante au Canada et en Afrique'
                }
            ],
            liste: [
                {
                    libele: 'Partenaires représentants avec logo :',
                    option: [
                        'Collège boréal',
                        'Institut du leadership en gestion',
                        'Les organismes pour l’entrepreneuriat',
                        'DIGIHUB',
                        'Agoralliance',
                        'Entreprendre ici'
                    ]
                }
            ]
        },
        {
            title: 'Repreunariat',
            para: [
                {
                    p: "La CCCA se donne pour mandat d’attirer des investisseurs, des gens d’affaires basés en Afrique et qui souhaitent reprendre une entreprise en général et au Québec en particulier. Le repreneuriat est un programme économique qui est particulièrement mis en avant par le gouvernement du Québec à travers le ministère de l’économie, de l’innovation et de l’énergie ainsi que le ministère de l’immigration, de la francisation et de l’intégration.Ce programme repose sur une problématique: près de 25% des 65 ans et plus ont l’intention de fermer leurs entreprises par manque de relève.Moins de 20% des entrepreneurs résidants au Québec ont l’intention de reprendre les entreprises existantes.Plus de 60% sont plutôt intéressés à démarrer une entreprise."
                }
            ],
            liste: [
                {
                    libele: 'Les avantages avec le programme du repreneuriat sont directement perceptibles :',
                    option: [
                        'Il est plus facile d’obtenir du financement parce que l’entreprise présente déjà un historique financier et des actifs',
                        'L’image de marque et la réputation de l’entreprise sont déjà établies',
                        'Les produits au service de l’entreprise sont déjà bien alignés dans un marché concurrentiel',
                        'Il y a peu de risque de faillite car l’entreprise est déjà dans sa phase productive',
                        'Il existe déjà une relation entre les clients et les fournisseurs',
                        'Le personnel est déjà suffisamment formé et aguerri',
                        'Dans certains camps, l’ancien dirigeant peut aider à faire la transition pour que la reprise soit optimale',
                    ]
                }
            ]
        },
        {
            title: 'Francophonie',
            para: [
                {
                    p: "La CCCA est avant tout un marché économique francophone qui relie la francophonie canadienne et celle africaine.Elle s’ouvre ensuite vers les économies anglophones afin de susciter des dynamiques d’affaires impulsées par la volonté de commercialiser en langue française. À travers la francophonie, la CCCA entend attirer les économies diverses au sein d’un écosystème drainé par la langue française.La CCCA offre également des espaces d’apprentissage du français comme langue d’affaires.",
                },
                {
                    p: "À ce titre, elle offre un programme de francisation à tout entrepreneur ou investisseur qui choisit de s’installer au Québec ou au sein de communautés francophones au canada."
                }
            ],
            liste: [
                
            ]
        },
        {
            title: 'Coorporation Internationnale',
            para: [
                {
                    p: "La CCCA regroupe en son sein des expertises pour conduire des missions commerciales aussi bien au canada qu’en Afrique.Ce service vise à accompagner des gouvernements, institutions publiques ou para publiques, des organisations à but non lucratif, des entreprises, qui souhaitent effecteur une mission commerciale, de prospection ou d’exploration en vue de générer en développement d’affaires de partenariats ou de représentations.",
                }
            ],
            liste: [
                
            ]
        },
        {
            title: 'Maillage d\'Affaires',
            para: [
                {
                    p: "La CCCA offre un programme de maillage d’affaires à travers des salons, des forums, des foires organisées sous forme de rencontre B to B et B to C et dont l’objectif est de permettre aux participants de nouer ou conclure des alliances d’affaires entre le canada et l’Afrique.À ce titre, la CCCA a plusieurs concepts évènementiels d’affaires qui se tiennent annuellement au canada ou dans l’un des pays africains où elle possède une délégation.",
                },
                {
                    p: "La CCCA collabore également avec des partenaires organisateurs pour attirer des délégations canadiennes d’entrepreneurs ou investisseurs pour participer à des évènements économiquement importants en Afrique, et inversement, cette dernière à travers ses délégués pays, mobilise des délégations africaines d’entrepreneurs et ou d’investisseurs pour prendre part à des événements d’affaires organisés au Canada.",
                }
            ],
            liste: [
                
            ]
        }
    ]
  return (
      <div>
          {/* <h1 className='text-[1.5rem] font-bold text-center'>{nameservice}</h1> */}
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-4'>
              {datas?.find((x) => x.title === nameservice)?.para?.map((y,index) =>
                  <p key={index} className='text-[1.1rem]'>{y.p}</p>
                )}
            </div>
            <div>
               {datas?.find((x) => x.title === nameservice)?.liste?.map((y,index) =>
                    <div key={index}>
                   <h6 className='text-[1.1rem]'>{y.libele}</h6>  
                   <ul className='list-disc ps-10 mt-2'>
                        {y.option?.map((z,ind)=>
                            <li key={ind}>{z}</li>
                        )}
                   </ul>
                   </div>
               )}
            </div> 
          </div>
      </div>
  )
}
