import React from 'react'
import Annimated from '../../components/Annimated'

export default function DirectionGenerale() {
  return (
    <Annimated>
      Direction Exécutive
    </Annimated>
  )
}
