import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Banner from '../../components/Banner'
import Annimated from '../../components/Annimated'


export default function LayoutsDetail() {
  const {nameservice}= useParams()
    const data = [
        'Entrepreunariat',
        'Francophonie',
        'Repreunariat',
        'Coorporation Internationnale',
        'Maillage d\'Affaires',
        'Formations',
        'Incubations',
        'Juméllage Consulaire'
    ]
    
  return (
    <Annimated>
    <Banner title={nameservice} />
    <div className='px-[5%] py-14 gap-10 flex max-md:flex-col w-full'>
          <div className='w-[25%] max-md:w-full bg-gray-200'>
            <ul className='flex flex-col'>
                {data?.map((x,index)=>
                    <li key={index} className="border-b-[1px] border-gray-300 last-of-type:border-none"><NavLink className='px-5 service-side py-4 text-[1rem] font-semibold block' to={`/a/Services/${x}`}>{x}</NavLink></li>
                )}
            </ul>
          </div>
          <div className='w-[75%] max-md:w-full'><Outlet /></div>
    </div>
    </Annimated>
  )
}
