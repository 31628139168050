import React from 'react'
import profile1 from '../../assets/apropos/240619_22H43-44_8721-HRres_10po@300dpi-sRgb.jpg'
import profile2 from '../../assets/apropos/IMG-20241011-WA0001.jpg'
import profile3 from '../../assets/apropos/240619_20H36-48_8490-HRres_10po@300dpi-sRgb.jpg'
import Annimated from '../../components/Annimated'

export default function ConseilAdministration() {
    const data = [
        {
            profile: profile1,
            name: 'Dr. Armand NGAKETCHA',
            titre:'President de la CCCA'       
        },
        {
            profile: profile2,
            name: 'Karl Miville de Chêne',
            titre:'Vice-Président de la CCCA'       
        },
        {
            profile: profile3,
            name: 'Tania Louis',
            titre:'Sécretaire Générale'       
        },
    ]
    
    return (
        <Annimated className='grid grid-cols-3 max-lg:grid-cols-2 max-sm:grid-cols-1 gap-8'>
            {data?.map((x,index)=>
            <div key={index}>
                <div className='relative h-[240px]'>
                    <img className='absolute top-0 left-0 object-cover object-top w-full h-full' src={x.profile} alt='#' />
                </div>
                <div className='flex flex-col items-center justify-center py-3 px-2'>
                    <h6 className='font-bold'>{x.name}</h6>
                    <span className='text-[.9rem]'>{x.titre}</span>
                </div>
            </div>
            )}
        </Annimated>
    )
}
