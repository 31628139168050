import React from 'react'
import logos from '../../assets/2725.jpg'




export default function Mission() {
    return (
        <div className='grid grid-cols-2 bg-tiers max-md:grid-cols-1 gri w-full gap-16 max-md:gap-2 px-[5%] py-md max-md:py-5'>
            <div className='relative order-2 overflow-hidden rounded-md max-md:hidden grille'>
                <img src={logos} className='absolute object-cover object-center w-full h-full' alt='#' />
            </div>
            <div className='flex flex-col order-1  max-md:pb-4 gap-3'>
                {/* <h3 className='text-secondary text-[1.6rem] w-[78%] max-md:w-[100%] max-md:text-[1.3rem] font-semibold cpitalize' style={{ lineHeight: '35px' }}>La CCCA: Chambre de Commerce Canada Afrique</h3> */}
                <h1 className='text-primary text-[2.1rem] max-md:text-[2rem] font-semibold'>Une mission inchangée,<br></br >une vision élargie</h1>
                <p className='text-[1.1rem] text-justify max-md:text-[1rem] font-light'>
                    Bien que notre nom et notre portée évoluent, notre mission reste fidèle à nos
                    valeurs fondamentales. Nous visons à être un <strong className='text-secondary'>pôle exceptionnel de
                    développement économique solidaire</strong> et à favoriser une collaboration
                    enrichissante pour les communautés d’affaires des deux côtés de l’Atlantique.
                    Sous la direction éclairée du <mark className='font-semibold bg-transparent text-primary'>Dr Armand</mark>, La CCCA continuera de bâtir des ponts
                    économiques entre le Canada et l’Afrique francophone, tout en accueillant de nouvelles opportunités et des partenaires anglophones, favorisant ainsi une
                    approche inclusive et intégrée.
                </p>
            </div>
        </div>
    )
}
