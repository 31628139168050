import React from 'react'
import Navbar from '../components/Navbar/index';
import logo from '../assets/1x/icone.png'
import logo1 from '../assets/1x/icone.png'
import { A11y, Autoplay, EffectFade, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import banner2 from '../assets/background.jpg'

export default function Incubations() {
    const Banners = (props) => {
        const { image, descrip } = props
        return (
            <div className='relative h-[400px] max-md:h-[250px] bg-opacity-45 bg-primary'>
                <img src={image} className='object-cover w-full h-full' alt='' />
                <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 max-md:bg-opacity-50'></div>
                <div className='absolute w-[100%] top-[50%] text-start -translate-y-[50%] bg-red z-[200] text-white flex flex-col px-[4%]'>
                    {/* <h1 className='text-deco'>{marque}</h1> */}
                    <div className='flex flex-col w-full max-md:justify-center'>
                        {/* <h1 className='mb-1 mt-1 text-[2rem] font-bold'>{titre}</h1> */}
                        <span className='text-[3.7rem] max-lg:text-[2rem] max-md:text-[1.6rem] text-center font-bold'>{descrip}</span>
                    </div>
                    {/* <Link className='font-medium text-[15px]' href={'#'}>READ MORE</Link> */}
                </div>
            </div>
        )
    }

    const data = [
        {
            titre: 'Développement de projets multi-acteurs',
            duree: '2h00',
            langue: 'Français'
        },
        {
            titre: 'Entrepreneuriat et levée de fonds publics pour la recherche et insitutionnels',
            duree: '2h00',
            langue: 'Français'
        },
        {
            titre: 'Développement et financement d\'un incubateur',
            duree: '2h00',
            langue: 'Français'
        },
        {
            titre: 'Formation a l\'entrepreneuriat',
            duree: '1h30',
            langue:'Français'
        },
    ]
    return (
        <>
            {/* <main> */}
            {/* <header className='relative'>
                <Swiper
                    modules={[Scrollbar, A11y, Autoplay, EffectFade]}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    spaceBetween={0}
                    slidesPerView={1}
                    effect='fade'
                >
                    <SwiperSlide className=''>
                        <Banners
                            titre="L'expertise au service de votre Cybersécurité"
                            image={banner2}
                            descrip="Incubations"
                        />
                    </SwiperSlide>
                </Swiper>
                <div className='absolute z-[100] top-0 w-full'>
                    <Navbar logo={logo} logos={logo1} />
                </div>
            </header> */}
            <div className="px-[5%] w-full py-md max-md:py-12">
                <p className='text-[1.5rem] max-md:text-[1rem] text-center'>La CCCA à travers son programme d’incubation et d’accélération intitué « CCCA-Propuls » accompagne des entrepreneurs résident au canada de l’idéation au démarrage d’une entreprise.  A travers ses 4 parcours, CCCA-Propuls vous permettra d’acquérir des ressources  clés pour démarrer une entreprise au Canada et la réaliser.</p>
                <div className='grid grid-cols-3 gap-10 max-lg:grid-cols-2 max-md:grid-cols-1 mt-9'>
                    {data?.map((x,index)=>
                    <div key={index} className='cursor-pointer relative border-[2px] border-b-[7px] rounded-md transition-all hover:scale-105 duration-500 hover:bg-primary hover:text-white border-primary p-14 pb-lg flex flex-col gap-3'>
                            <h1 className='text-[1.2rem]'>{x.titre}</h1>
                            <div className='absolute flex flex-col gap-1 bottom-7'>
                                <span className='font-medium opacity-75'>Langue: {x.langue}</span>
                                <span className='font-medium opacity-75'>Durée: {x.duree}</span>
                            </div>
                    </div>
                    )}
                </div>
            </div>
        </>
    )
}
