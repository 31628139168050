import React from 'react'
// import Navbar from '../components/Navbar/index';
// import logo from '../assets/1x/icone.png'
// import logo1 from '../assets/1x/icone.png'
// import { A11y, Autoplay, EffectFade, Scrollbar } from 'swiper/modules';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
// import banner2 from '../assets/background.jpg'
import activite1 from '../assets/15846.jpg'
import { ArrowRight01Icon,ArrowLeft01Icon } from 'hugeicons-react';
import Banner from '../components/Banner';
import Infolettre from '../components/Infolettre';
import Annimated from '../components/Annimated';
// import Select from 'react-select';
// <Select
//     className="m-lg-2 yo"
//     options={options}
//     placeholder="Type d'entreprise"
//     onChange={(e) => setJanv(e.value)}
// // isDisabled
// />
export default function Actualites() {

    const data = [
        {
            titre: 'Plateforme d’Échange Commercial',
            descrip: 'Création d\'une plateforme numérique qui met en relation les entreprises canadiennes et africaines. Cette plateforme pourrait permettre le partage d\'informations sur les produits, services, opportunités d\'investissement et projets en cours.'
        },
        {
            titre: 'Webinaires et Ateliers de Formation',
            descrip: "Organisation de webinaires sur des sujets clés tels que l'entrée sur le marché, la culture d'affaires, la législation locale et les pratiques commerciales durables."
        },
        {
            titre: 'Missions Commerciales',
            descrip: "Organisation de missions commerciales régulières, tant au Canada qu’en Afrique, pour permettre aux entreprises de rencontrer des partenaires potentiels et de découvrir les marchés locaux."
        },
        {
            titre: 'Développement et financement d\'un incubateur',
            descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'
        },
        {
            titre: 'Développement et financement d\'un incubateur',
            descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'
        },
        {
            titre: 'Développement et financement d\'un incubateur',
            descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'
        }
    ]
    return (
        <Annimated>
            <Banner title='Actualités'/>
            <div className="px-[5%] w-full flex gap-[4rem] py-14 max-md:py-12">
                <div className='w-[75%] max-md:w-full'>
                    <div className='flex flex-col gap-8 pb-4 border-b-[1px] border-primary'>
                            <ul className='flex gap-4'>
                                <li className='p-2 px-6 bg-primary text-white text-[.8rem] font-semibold'>TOUT</li>
                                <li className='p-2 px-6 border-primary border-[1px] text-primary text-[.8rem] font-semibold'>CCCA</li>
                                <li className='p-2 px-6 border-primary border-[1px] text-primary text-[.8rem] font-semibold'>CCCA</li>
                            </ul>
                        <div className='flex items-center gap-3'>
                            <span className='font-semibold'>Type de publication: </span>
                            <select className='border-[1px] border-primary outline-0 p-2 px-2 w-[180px]'>
                                <option>Tout</option>
                                <option>Avis</option>
                                <option>Rapport</option>
                            </select>
                        </div>
                        <span className='text-[.8rem] font-semibold'>50 Resultats</span>
                    </div>
                    <div className='flex flex-col mt-8'>
                        {data?.map((x, index) =>
                            <div key={index} className='relative w-full flex max-md:flex-col items-center py-5 flex-row-reverse gap-5 overflow-hidden transition-all duration-500 border-b-[1px] border-primary cursor-pointer '>
                                <div className='w-[35%] max-md:w-full'>                         
                                    <img src={activite1} alt='#' />
                                </div>
                                <div className='flex flex-col gap-3 w-[65%] max-md:w-full'>
                                    <h1 className='text-[1.6rem] max-md:text-[1.2rem] max-md:text-center font-semibold text-primary'>{x.titre}</h1>
                                    <span className='text-[1.1rem] max-md:text-[.95rem] font-light'>{x.descrip}</span>
                                    <span className='text-[.75rem] text-gray-500 font-semibold'>- 21 Sept 2024</span>
                                </div>
                            </div>
                        )}
                    
                    </div>
                    <div className='flex items-center justify-center w-full pt-10'>
                        <ul className='flex gap-1 p-2'>
                            <li className='p-1 px-4'><ArrowLeft01Icon /></li>
                            <li className='p-1 px-4 text-white bg-secondary'>1</li>
                            <li className='p-1 px-4 bg-gray-300'>2</li>
                            <li className='p-1 px-4 bg-gray-300'>3</li>
                            <li className='p-1 px-4 bg-gray-300'>4</li>
                            <li className='p-1 px-4'><ArrowRight01Icon /></li>
                        </ul>
                    </div>
                </div>
                <div className='w-[25%] max-md:hidden flex flex-col gap-8'>
                            <div className='bg-primary text-white flex flex-col gap-4 px-8 py-[1.5rem]'>
                                <h3 className='text-[1.3rem] font-medium'>Vos choix</h3>
                                <span className='text-[.85rem]'>publications</span>
                                <button className='border-[1px] border-white py-1 px-4 w-full'>Rénitialisez votre choix</button>
                            </div>
                            <Infolettre />
                        </div>
                </div>
        </Annimated>
    )
}
