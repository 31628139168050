// import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import Aos from 'aos'
import 'aos/dist/aos.css'
import CoomingSoon from './pages/CoomingSoon';
import Formation from './pages/Formatione copy';
import Incubations from './pages/Incubations';
import Contacts from './pages/Contact';
import Evenements from './pages/Evenements';
import Missions from './pages/Missions';
import Services from './pages/Services';
import Acceuil from './pages/Acceuil';
import Layouts from './pages/Layouts';
import BureauxAfrique from './pages/Bureau-Afrique/BureauxAfrique';
import AfriqueLayouts from './pages/Bureau-Afrique/AfriqueLayouts';
import indexes from './pages/Bureau-Afrique/indexes';
import Pays from './pages/Bureau-Afrique/Pays';
import 'jquery/dist/jquery.min.js';
import { AnimatePresence } from 'framer-motion';
import DevenirMembre from './pages/DevenirMembre';
import LayoutsDetail from './pages/Services/LayoutsDetail';
import ServiceDetails from './pages/Services/ServiceDetails';
import Actualites from './pages/Actualites';
import Activites from './pages/Activites';
import LayoutsActivite from './pages/Activites/LayoutsActivite';
import ActiviteDetail from './pages/Activites/ActiviteDetail';
// import AproposDetail from './pages/Apropos/AproposDetail';
import LayoutsApropos from './pages/Apropos/LayoutsApropos';
import Presentations from './pages/Apropos/Presentations';
import ConseilAdministration from './pages/Apropos/ConseilAdministration';
import DirectionGenerale from './pages/Apropos/DirectionGenerale';
import Gallerie from './pages/Apropos/Gallerie';
import Contact from './pages/Apropos/Contact';
// import { lazy } from 'react';
// const Layout =lazy(()=> import('./pages/Layout.js'));
// const Formation = lazy(() => import('./pages/Formation'));
// const Incubations = lazy(() => import('./pages/Incubations'));
// const Activite = lazy(() => import('./pages/Activite'));
// const Contacts = lazy(() => import('./pages/Contact'));
function App() {

  
  window.addEventListener('load', () => {
    Aos.init({
      duration: 700,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
  });
  return (
    <AnimatePresence>
      <Routes>
        <Route path='/' Component={Acceuil} >
          {/* <Route index Component={Acceuil} /> */}
        </Route>
        <Route path="/a/" Component={Layouts} >
          <Route index Component={Home} />
          <Route path='Formations' Component={Formation} />
          <Route path='Mission' Component={Missions} />
          <Route path='Activites/' >
            <Route index Component={Activites} />
            <Route path=':nameactivite' Component={LayoutsActivite}>
              <Route index Component={ActiviteDetail} />
            </Route>
          </Route>
          <Route path='Services/' >
            <Route index Component={Services}/>
            <Route path=':nameservice' Component={LayoutsDetail}>
              <Route index Component={ServiceDetails} />
            </Route>
          </Route>
          <Route path='Apropos-de-nous/' Component={LayoutsApropos}>
            <Route path='Présentation' Component={Presentations} />
            <Route path="Conseil d'administration" Component={ConseilAdministration} />
            <Route path="Direction Exécutive" Component={DirectionGenerale} />
            <Route path="Gallerie" Component={Gallerie} />
            <Route path="Contacts" Component={Contact} />
            {/* <Route path=':detail' >
              <Route index Component={AproposDetail} />
            </Route> */}
          </Route>
          <Route path='Incubation' Component={Incubations} />
          <Route path='Evenements' Component={Evenements} />
          <Route path='Actualites' Component={Actualites} />
          <Route path='Contacts' Component={Contacts} />
          <Route path='Devenir-membre' Component={DevenirMembre} />
          <Route path='Bureau/'>
            <Route index Component={indexes} />
            <Route path=':nation/'>  
              <Route index Component={Pays} />
              <Route path=':region' Component={AfriqueLayouts} >
                <Route index Component={BureauxAfrique} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" Component={CoomingSoon} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
