import React from 'react'
import contactPhoto from '../../assets/2151440495.jpg'
import { TelephoneIcon } from 'hugeicons-react'
import { Location01Icon } from 'hugeicons-react'
import { Globe02Icon } from 'hugeicons-react'
import { Mail01Icon } from 'hugeicons-react'
import { NavLink } from 'react-router-dom'
import { Resend } from 'resend';

function Contact() {
  const resend = new Resend('re_95jjAfNu_LLXjfHvktru3bxNH6Quhhvmp');

  const send= async()=>{
    const { data, error } = await resend.emails.send({
        from: 'lionelfotso413@gmail.com',
        to: ['lionelfotso4130@gmail.com'],
        subject: 'hello world',
        text: 'it works!',
    });
    if(error){
      console.log(error)
    } 
      console.log(data)
  }
  return (
    <div className='px-[5%] relative py-md w-full'>
      <img src={contactPhoto} className='absolute z-1 top-0 left-0 object-cover h-full w-full' alt='' />
      <div className='absolute w-full h-full z-2 top-0 left-0 bg-primary bg-opacity-80'></div>
      <div className='flex flex-col'>
      <h1 className='text-white z-10 text-center text-[2.5rem] max-md:text-[1.8rem] mb-5 font-semibold'>Nous Contactez</h1>
        <div className='grid grid-cols-2 max-md:grid-cols-1 gap-14 max-sm:grid-cols-1'>
              <div className='relative overflow-hidden gap-5 text-white pt-5'>
                  <div className='flex gap-6 items-center font-medium mb-10 text-[1.5rem]'>
                      <Mail01Icon size={'3rem'}/>
                      <span>info@cc-ca.ca</span>
                  </div>
                  <div className='flex gap-6 items-center font-medium mb-10 text-[1.5rem]'>
                    <TelephoneIcon size={'3rem'} />
                    <span>+1 (438) 388-3606</span>
                  </div>
                  <div className='flex gap-6 items-center font-medium mb-10 text-[1.5rem]'>
                    <Globe02Icon size={'3rem'} />
                    <NavLink to={'http://cc-ca.ca'}>www.cc-ca.ca</NavLink>
                  </div>
                  <div className='flex gap-6 items-center font-medium text-[1.5rem]'>
                    <Location01Icon size={'4rem'} />
                    <span>400 - 257 rue Sherbrooke Est, Montréal, H2X 1E3, QC, Canada</span>
                  </div>
              </div>
              <div className='relative'>
                  {/* <p className="text-[1.5rem] max-md:text-[1.2rem] font-semibold w-full text-center mb-3 text-white capitalize ">posez nous vos questions !</p> */}
                  <div className='flex flex-col gap-4 mt-5'>
                      <input type='text' className='p-3 px-4 rounded-sm outline-0 border-none ' placeholder='Entrez votre nom' />
                      <input type='text' className='p-3 px-4 rounded-sm outline-0 border-none ' placeholder='Entrez votre mail' />
                      <input type='number' className='p-3 px-4 rounded-sm outline-0 border-none ' placeholder='Entrez votre numero' />
                      <textarea rows={4} className='p-3 px-4 rounded-sm outline-0 border-none ' placeholder='Posez vos questions'/>
                      <button onClick={()=>{send()}} className='p-3 text-white rounded-md bg-secondary'>Envoyez</button>
                  </div>
              </div>
        </div>
      </div>
    </div>
  )
}

export default Contact