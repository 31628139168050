'use client'
import React from 'react'
import image1 from '../../assets/globes.png'
import image2 from '../../assets/agreement_3375267.png'
import image3 from '../../assets/academic-cap.png'
import image4 from '../../assets/shields.png'
import image5 from '../../assets/diamond_5214557.png'
import image6 from '../../assets/eye_14174521.png'
import { ArrowRight02Icon } from 'hugeicons-react'
import { useNavigate } from 'react-router-dom'
// import image2 from '../assets/main.svg'
// import image3 from '../assets/business.svg'
// import image4 from '../assets/developpement.svg'

function Avantage() {
    const data=[
        {
            image:image4,
            titre:'Un Placement sûr',
            descrip:'La CCCA comme acteur incontournable pour vous offrir des opportunités.',
            libelle: 'Devenez Membre',
            link:'/a/Devenir-membre'
        },
        {
            image: image1,
            titre: 'La Force du réseau',
            descrip: 'Tisser des liens avec d\'autres gens \'affaires pour une croissance assurée.',
            libelle: 'Liste des Membres',
            link: '/a/Devenir-membre'
        },
        {
            image: image3,
            titre: 'Accès aux formations',
            descrip: 'Répondre aux besoins des membres en offrant des formations diversifiées et accessibles.',
            libelle: 'Calendrier',
            link: '/a/Devenir-membre'
        },
        {
            image: image6,
            titre: 'Visibilité',
            descrip: 'La CCCA se lie avec ses partenaires afin d\'offrir l\'espace publicitaire « Entreprise du mois ». Réservez le mois qui vous convient pour faire parler de vous!',
            libelle: 'Plus de details',
            link: '/a/Devenir-membre'
        },
        {
            image: image2,
            titre: 'Un partenariat influent',
            descrip: 'Plusieurs opportunités pour une implication plus importante au sein de la CCCA comme partenaire annuel ou comme commanditaire d\'événement.',
            libelle: 'Devenez partenaire',
            link: '/a/Devenir-membre'
        },
        {
            image: image5,
            titre: 'Offres exclusives',
            descrip: 'Économisez avec des promotions offertes PAR les membres POUR les membres de la CCCA.',
            libelle: 'Voir les promotions',
            link: '/a/Devenir-membre'
        }
    ]
    const navigate= useNavigate()
    const changePage = (x) => {
        navigate(x)
    }
  return (
    //   <div className='bg-tiers'>
      <div className='w-full bg-opacity-1 relative bg-primary overflow-hidden px-[5%] py-md'>
          <div className='absolute flex items-center justify-center w-[800px] h-[800px] rounded-[100%] bg-gray-100 -right-[10%] -top-[15%]'>
            <div className='w-[80%] ms-[13%] -mt-[11%] z-5 h-[80%] rounded-[100%] bg-secondary'></div>
          </div>
          <div className='flex flex-col'>
          <h1 className='text-white text-[2.3rem] z-[100] max-md:text-[1.8rem] max-md:text-center mb-6 font-semibold'>Nos Avantages</h1>
          <div className='grid grid-cols-3 gap-8 max-lg:grid-cols-2 max-sm:grid-cols-1'>
              {data?.map((x,index)=>
              <div key={index} className='relative flex flex-col items-start gap-4 px-10 py-8 pb-md bg-white rounded-md max-md:px-7'>
                      <img src={x.image} className='w-[65px] h-[65px]' alt='#'></img>
                <h1 className='text-[1.5rem] max-md:text-[1.1rem] text-primary font-bold'>{x.titre}</h1> 
                <span className='text-[1rem] font-light'>{x.descrip}</span>
                <button onClick={()=>changePage(x.link)} className='bg-secondary  absolute bottom-6 text-white font-medium flex items-center gap-2 px-5 text-[.8rem] py-2 rounded-md'>{x.libelle} <ArrowRight02Icon /> </button>
              </div>
              )}
              </div>
          </div>
          </div>
    //   </div>
  )
}

export default Avantage