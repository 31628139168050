import React from 'react'
import Navsbar from '../components/Navbar/Navsbar'
import { Outlet } from 'react-router-dom'
import Footer from '../components/Footer'
import { motion } from 'framer-motion'

export default function Layouts() {
  const config = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit:{opacity:0}
  }
  return (
    <motion.div className='relative' variants={config} initial='initial' animate='animate' exit='exit' transition={{ duration:1,ease:"easeInOut" }}>
        <Navsbar />
        <Outlet />
        <Footer />
    </motion.div>
  )
}
