import React from 'react'
// import Navbar from '../components/Navbar/index';
// import logo from '../assets/1x/icone.png'
// import logo1 from '../assets/1x/icone.png'
// import { A11y, Autoplay, EffectFade, Scrollbar } from 'swiper/modules';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
// import banner2 from '../assets/background.jpg'
// import activite1 from '../assets/15846.jpg'
// import { Search01Icon} from 'hugeicons-react';
import Banner from '../components/Banner';
import imag from '../assets/apropos/240619_22H03-40_8569-HRres_10po@300dpi-sRgb.jpg'
import imag2 from '../assets/apropos/240619_20H48-49_8508-HRres_10po@300dpi-sRgb.jpg'
import imag3 from '../assets/apropos/240619_19H57-54_8404-HRres_10po@300dpi-sRgb.jpg'
import imag4 from '../assets/apropos/240619_22H03-40_8569-HRres_10po@300dpi-sRgb.jpg'
import Annimated from '../components/Annimated';
// import Select from 'react-select';
// <Select
//     className="m-lg-2 yo"
//     options={options}
//     placeholder="Type d'entreprise"
//     onChange={(e) => setJanv(e.value)}
// // isDisabled
// />
export default function Evenements() {

  const Banners = (props) => {
    const { image, descrip } = props
    return (
      <div className='relative h-[400px] max-md:h-[250px] bg-opacity-45 bg-primary'>
        <img src={image} className='object-cover w-full h-full' alt='' />
        <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 max-md:bg-opacity-50'></div>
        <div className='absolute w-[100%] top-[50%] text-start -translate-y-[50%] bg-red z-[200] text-white flex flex-col px-[4%]'>
          {/* <h1 className='text-deco'>{marque}</h1> */}
          <div className='flex flex-col w-full max-md:justify-center'>
            {/* <h1 className='mb-1 mt-1 text-[2rem] font-bold'>{titre}</h1> */}
            <span className='text-[3.7rem] max-lg:text-[2rem] max-md:text-[1.6rem] text-center font-bold'>{descrip}</span>
          </div>
          {/* <Link className='font-medium text-[15px]' href={'#'}>READ MORE</Link> */}
        </div>
      </div>
    )
  }

  const data = [
    {
      imag:imag,
      titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
      descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has'
    },
    {
      imag:imag2,
      titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
      descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has'
    },
    {
      imag:imag3,
      titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
      descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has'
    },
    {
      imag:imag4,
      titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
      descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has'
    }
  ]

  const datas = [
    {
      imag:imag,
      titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
      descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has'
    },
    {
      imag:imag,
      titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
      descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has'
    },
    {
      imag:imag,
      titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
      descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has'
    },
    {
      imag:imag,
      titre: 'Rendez vous d\'Affaire de la Francophonie (La RAF24)',
      descrip: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has'
    }
  ]
  return (
    <Annimated>
      {/* <main> */}
      {/* <header className='relative'>
        <Swiper
          modules={[Scrollbar, A11y, Autoplay, EffectFade]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          spaceBetween={0}
          slidesPerView={1}
          effect='fade'
        >
          <SwiperSlide className=''>
            <Banners
              titre="L'expertise au service de votre Cybersécurité"
              image={banner2}
              descrip="Evènements"
            />
          </SwiperSlide>
        </Swiper>
        <div className='absolute z-[100] top-0 w-full'>
          <Navbar logo={logo} logos={logo1} />
        </div>
      </header> */}
      <Banner title={'Evenements'} />
      <div className="px-[5%] flex max-md:flex-col gap-14 w-full py-14 max-md:py-12">
        <div className='flex flex-col w-[66%] max-md:w-full gap-1'>
          <p className='text-[1.8rem] text-primary font-semibold max-md:text-[1.2rem] pb-2 border-b-[1px] border-primary'>Evènements Recents</p>
          <div className='grid grid-cols-1 gap-3 mt-4'>
            {data?.map((x, index) =>
              <div key={index} className='relative flex flex-row-reverse max-md:flex-col gap-3 border-b-[1px] border-primary last-of-type:border-none overflow-hidden transition-all duration-500 rounded-sm shadow-sm cursor-pointer '>
                <div className='relative w-[30%] max-md:w-full max-md:h-[300px] h-[150px]'>
                  <img className='absolute w-full object-cover h-full' src={x.imag} alt='#' />
                </div>
                <div className='flex flex-col gap-2 w-[70%] max-md:w-full px-8 max-md:px-5 max-md:text-center pb-5'>
                  <h1 className='text-[1.3rem] font-semibold text-primary'>{x.titre}</h1>
                  <span className='text-[.95rem] font-light'>{x.descrip}</span>
                  <span className='text-gray-400 text-[.8rem]'>16, Janv 2024</span>
                </div>
                {/* <div className=' absolute'>
                  <span className='text-gray-400 text-[.8rem]'>16, Janv 2024</span>
                </div> */}
              </div>
            )}
            </div>
        </div>
        <div className='flex flex-col w-[34%] max-md:w-full gap-1'>
          <p className='text-[1.8rem] text-primary font-semibold max-md:text-[1.4rem]  pb-2 border-b-[1px] border-primary'>Evènements Passés</p>
          <div className='grid grid-cols-1 gap-6 mt-4 gap-12max-lg:grid-cols-2 max-md:grid-cols-1'>
            {datas?.map((x, index) =>
              <div key={index} className='relative w-full flex gap-1 overflow-hidden transition-all duration-500 rounded-sm shadow-md cursor-pointer '>
                <div className='w-[40%] relative'>
                  <img className='absolute w-full h-full object-cover' src={x.imag} alt='#' />
                </div>
                <div className='w-[60%] py-3 px-3 flex flex-col gap-1'>
                  <h1 className='text-[1rem] font-semibold text-primary'>{x.titre}</h1>
                  <span className='text-[.8rem] font-light'>{x.descrip}</span>
                </div>
                {/* <div className='bottom-0 left-0 w-full px-8 py-2 border-t-[1px] absolute'>
                  <span className='text-gray-400 text-[.8rem]'>16, Janv 2024</span>
                </div> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </Annimated>
  )
}
