'use client'
import React, {useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import pre1 from '../../assets/loader.PNG'
import video from '../../assets/videos/mont.mp4'
import { PlayCircleIcon } from 'hugeicons-react';
import { PauseIcon } from 'hugeicons-react';
import 'jquery/dist/jquery'
import $ from 'jquery'

export default function Presentation() {
    const [play,setPlay]=useState(false)

    $(document).ready(function () {
        const vid = $('.videos')[0];
        const play = $('.btn-play')
        const pause = $('.btn-pause')
        play.click(() => { vid.play();setPlay(true) })
        pause.click(() => { vid.pause(); setPlay(false) })
        $('.videos').on('ended', ()=>
            $(this).load()
        )
    });

    
    return (
        <div className='grid grid-cols-2 max-lg:grid-cols-1 w-full gap-14 max-md:gap-2 px-[5%] py-md max-md:py-10'>
            <div className='relative overflow-hidden rounded-md grille'>
                    <div className={`absolute top-[50%] bg-red left-[50%] ${play?'':'hidden'} -translate-x-[50%] -translate-y-[50%] cursor-pointer z-50 btn-pause`}>
                        <PauseIcon size={70} color='white' />
                    </div>
                  
                    <div className={`absolute top-[50%] ${play ? 'hidden' : ''} bg-red left-[50%] -translate-x-[50%] -translate-y-[50%] cursor-pointer z-50 btn-play`}>
                        <PlayCircleIcon size={70} color='white' />
                    </div>   
                <video
                    width="100%"
                    height="100%"
                    poster={pre1}
                    // src={video}
                    autoPlay={false}
                    title="Youtube Player"
                    className='absolute w-full h-full videos max-lg:relative'
                >
                    <source src={video} />
                </video>
            </div>
            <div className='flex flex-col gap-2'>
                <h3 className='text-secondary text-[1.6rem] w-[78%] max-md:w-[100%] max-md:text-[1.3rem] font-semibold cpitalize' style={{ lineHeight:'35px' }}>La CCCA: Chambre de Commerce Canada Afrique</h3>
                <h1 className='text-primary text-[2.1rem] max-md:text-[2rem] font-semibold'>Présentation</h1>
                <p className='text-[1rem] text-justify font-light'>La Chambre de Commerce Canada Afrique est un organisme chargé de représenter les intérêts des entreprises commerciales, industrielles et de services, d’assurer la formation des entrepreneurs et d’apporter un appui aux entreprises. Elle représente les professionnels des secteurs du commerce, de l&apos;industrie et des services auprès des autorités publiques locales et internationales intervenant dans les domaines relevant de ses missions.La CCCA assume des missions à caractère représentatif, consultatif, de soutien et de promotion.</p>
            </div>
        </div>
    );
}
