import { Pdf02Icon } from 'hugeicons-react'
import React from 'react'
import { NavLink } from 'react-router-dom'
import fich from '../../assets/apropos/presentation.pdf'
import imag from '../../assets/ad.jpeg'
import Annimated from '../../components/Annimated'

export default function Presentations() {
  return (
    <Annimated className='flex flex-col gap-4'>
      <div className='relative w-full h-[175px]'>
        <img className='w-full h-full absolute object-cover object-top' src={imag} alt='#' />
      </div>
      <p className='text-justify'>La Chambre de Commerce Canada Afrique est un organisme chargé de représenter les intérêts des entreprises commerciales, industrielles et de services, d’assurer la formation des entrepreneurs et d’apporter un appui aux entreprises. Elle représente les professionnels des secteurs du commerce, de l'industrie et des services auprès des autorités publiques locales et internationales intervenant dans les domaines relevant de ses missions.La CCCA assume des missions à caractère représentatif, consultatif, de soutien et de promotion.</p>
      <NavLink  to={fich} className='p-3 w-[40%] mt-6 max-md:w-full rounded-sm font-semibold text-center text-white flex items-center justify-center gap-2 text-[.9rem] bg-secondary' target='_blank'>Télécharger le document de présentation <Pdf02Icon size={'1.2rem'}/> </NavLink>
    </Annimated>
  )
}
