import React from 'react'

export default function Infolettre() {
  return (
      <div>
          <h5 className='uppercase text-[0.7rem] mb-3 font-light'>Infolettre</h5>
          <div className='bg-[rgba(6,35,56,.05)] px-8 py-[2rem] flex flex-col gap-7 rounded-sm'>
              <h2 className='font-semibold text-[1.3rem] leading-7'>
                  Abonnez-vous aux infolettres de la Chambre
              </h2>
              <ul className='text-[1rem] font-extralight list-disc px-5 flex flex-col gap-2'>
                  <li>Ne ratez plus les activités profitables pour votre organisation</li>
                  <li>Recevez les plus récentes nouvelles directement par courriel</li>
                  <li>Restez au cœur de l'activité économique de la métropole</li>
                  <li>Ne manquez rien du blogue</li>
              </ul>
              <div className='font-semibold text-[1rem]'>S'inscrire</div>
          </div>
      </div>
  )
}
