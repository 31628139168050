import React, { useRef, useState } from 'react'
import Banner from '../../components/Banner'
import Infolettre from '../../components/Infolettre'
import { ArrowRight01Icon, Mail01Icon, TelephoneIcon } from 'hugeicons-react'
import { A11y, Autoplay, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import cteq from '../../assets/1x/cteq.webp'
import quebec from '../../assets/1x/quebec.png'
import pn from '../../assets/1x/pn.svg'
import chambers from '../../assets/1x/chambers.avif'
import fga from '../../assets/1x/fga.svg'
import fit from '../../assets/fit.png'
import hec from '../../assets/hec.svg'
import ifpf from '../../assets/ifpf.png'
import bannersMembre from '../../assets/2149241214.jpg'
import { NavLink } from 'react-router-dom';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import emailjs from "@emailjs/browser"
import Swal from 'sweetalert2';
import $ from 'jquery'
import Annimated from '../../components/Annimated';


export default function DevenirMembre() {

  const [loading, setLoading] = useState(false)
  const [opens, setOpens] = useState(false)
  const [progs, setProgs] = useState('')
  const [ecole, setEcole] = useState('')
  const [forma, setForma] = useState('')

  const nom = useRef('')
  const mail = useRef('')
  // const tel = useRef('')
  const message = useRef('')
  const stat = useRef('')
  // const prog = useRef('')

  const progress = useRef('')
  const nameE = useRef('')
  const data = [
    {
      link: 'https://ctequebec.com/',
      image: cteq,
    },
    {
      link: 'https://www.quebecinternational.ca/',
      image: quebec,
    },
    {
      link: 'https://www.printempsnumerique.ca/',
      image: pn,
    },
    {
      link: 'https://canadaafrica.ca/',
      image: chambers,
    },
    {
      link: 'https://fedefranco.ca/',
      image: fga,
    },
    {
      link: 'http://franchise-it-tech.com/',
      image: fit,
    },
    {
      link: 'https://www.hec.ca/',
      image: hec,
    },
    {
      link: 'http://franchise-it-tech.com/',
      image: ifpf,
    }
  ]

  const state = [
    {
      num: '+2000',
      libele:'Membres'
    },
    {
      num: '+50%',
      libele: 'Décideurs'
    },
    {
      num: '+10',
      libele: 'Activités'
    },
    {
      num: '+100',
      libele: 'Experts'
    }
  ]

  const membre = [
    {
      title: 'Èlargir son réseau',
      option: [
        'Profitez de plus de 150 occasions de réseautage variées chaque année qui répondent à tous les types de besoins',
        'Réseautez tout en découvrant des endroits inédits du Canada',
        'Participez à des rencontres d’affaires exclusives aux membres',
        'Profitez des périodes de prévente pour certaines de nos activités',
        'Rencontrez des acheteurs en quête de nouveaux produits',
        'Développez votre réseau à l’international et partez à la conquête de nouveaux marchés',
        'Inspirez-vous des expériences vécues par d’autres gens d’affaires',
        'Obtenez de 15 à 35 % de réduction sur le prix d’inscription à nos activités'
      ]
    },
    {
      title: 'Aprendre et S\'informer',
      option: [
        'Développez vos compétences grâce à nos ateliers de formation sur les meilleures pratiques d’affaires',
        'Apprenez l’abc du commerce international grâce à la formation offerte par la CCCA aux entreprises',
        'Inspirez-vous des plus grands noms de la scène politique et économique qui se partagent notre prestigieuse tribune',
        'Visitez notre centre de documentation pour obtenir de l’information stratégique',
        'Profitez de nos forums pour échanger sur les grands enjeux stratégiques',
        'Découvrez de nouvelles occasions d’affaires à l’étranger',
        'Obtenez de l’information sur les ressources disponibles pour le démarrage et la croissance de votre entreprise'
      ]
    },
    {
      title: 'Obtenir de la visibilité',
      option: [
        'Affichez-vous dans les versions papier et électronique de notre répertoire des membres',
        'Publiez gratuitement des offres spéciales aux autres membres dans notre site Web',
        'Inspirez-vous des plus grands noms de la scène politique et économique qui se partagent notre prestigieuse tribune',
        'Visitez notre centre de documentation pour obtenir de l’information stratégique',
        'Profitez de nos forums pour échanger sur les grands enjeux stratégiques',
        'Découvrez de nouvelles occasions d’affaires à l’étranger',
        'Obtenez de l’information sur les ressources disponibles pour le démarrage et la croissance de votre entreprise'
      ]
    },
    {
      title: 'Croître à l\'international',
      option: [
        'Affichez-vous dans les versions papier et électronique de notre répertoire des membres',
        'Publiez gratuitement des offres spéciales aux autres membres dans notre site Web',
        'Inspirez-vous des plus grands noms de la scène politique et économique qui se partagent notre prestigieuse tribune',
        'Visitez notre centre de documentation pour obtenir de l’information stratégique',
        'Profitez de nos forums pour échanger sur les grands enjeux stratégiques',
        'Découvrez de nouvelles occasions d’affaires à l’étranger',
        'Obtenez de l’information sur les ressources disponibles pour le démarrage et la croissance de votre entreprise'
      ]
    }
  ]

  const programme= [
        {   
            id: '1',
            titre: 'Programme de Perfectionnement des Capacités des Dirigeants et Entrepreneurs (PPCDE)',
            descrip: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
        },
        {
            id:'2',
            titre: 'Programme de formation sur le Repreneuriat',
            descrip: 'Test'
        }
  ]
  const ecoles = [
    {
      id: '1',
      titre: 'HEC Montreal'
    },
    {
      id: '2',
      titre: 'Universite Laval'
    },
    {
      id: '3',
      titre: 'ENAPS'
    },
    {
      id: '4',
      titre: 'Universite BOREAL'
    }
  ]

  const sous_form = [
    {
      ecol: 'HEC Montreal',
      detail: [
        {
          titre: 'Intelligence numérique(Big Data et IA)',
        },
        {
          titre: 'Communication, Marketing et vente',
        },
        {
          titre: 'Developpement Durable et Ethique',
        },
        {
          titre: 'Finances et Comptabilités',
        },
        {
          titre: 'Leadership et ressources humaines',
        },
        {
          titre: 'Stratégie, Innovation et prise de décision',
        }
      ]
    }
    // {universite laval}
    ,
    {
      ecol: 'Universite Laval',
      detail: [
        {
          titre: "Certificat Universitaire en Gouvernance des sociétés"
        },
        {
          titre: "Formation Continue en gouvenance"
        },
        {
          titre: "Formation En organisation"
        }
      ]
    }
    // {Boreal}
    ,
    {
      ecol: 'Universite BOREAL',
      detail: [
        {
          titre: "Entrepreneuriat"
        },
        {
          titre: "Commerce International (Import-Exprt Canada-Afrique/ Afrique/Canada)"
        },
        {
          titre: "Expertise minière"
        }
      ]
    }
  ]

  const sendEmail = (templateParams, template) => {
    let res;
    emailjs
      .send(
        'service_lsm0da1',
        template,
        templateParams,
        'GvD_PjWrMcfFFe5bp'
      )
      .then((result) => {
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Requête effectué avec success',
          showConfirmButton: false,
          timer: 1700,
          customClass: {
            title: 'text-[1rem] text-primary'
          }
        })
        setOpens(false)
      }, (error) => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Requête échoué',
          showConfirmButton: false,
          timer: 1700,
          customClass: {
            title: 'text-[1rem]'
          }

        })
      });
    return res
  }

  const choisirCanada = async () => {
    setLoading(true);
    const templateParams = {
      prog: programme?.find((x) => x.id === progs)?.titre,
      name: nom.current.value,
      ecole: ecole,
      progress: progress.current.value,
      forma: forma,
      nameE: nameE.current.value,
      mail: mail.current.value,
      message: message.current.value,
      stat: stat.current.value,
      to: 'info@cc-ca.ca'
    }
    await sendEmail(templateParams, 'template_lw9hzmf')
  }
  // const check = async (x, y) => {
  //   // await setChoix(x);
  //   if (y === 'afrique') {
  //     setOpen(!open)
  //   } else {
  //     setOpens(!opens)
  //   }
  // }
  $(function () {
    let toggleValue = false;
    $(document).on('click', '.settings-btn', function () {
      toggleValue = !toggleValue
      const pixel = $(this).siblings().prop('scrollHeight');
      if (toggleValue) {
        $(this).siblings().css('height', `${pixel}px`);
        $(this).children().last().addClass('rotate-[90deg]');
      } else {
        $(this).siblings().css('height', `0px`);
        $(this).children().last().removeClass('rotate-[90deg]');
      }
    })
  })

  const tables = [
    {
      type:'Entrepreneur',
      detail: [
        {
          title: 'ÉTUDIANT (25 ans et – )',
          descrip:
          {
            libelle: 'Pour les étudiants ayant à coeur le monde des affaires et voulant être en contact avec la communauté d’affaire au Québec et en Afrique',
            option: ['Réconnaissance lors de nos activités ou de nos communications', 'Exclusivité pour les événements réservés aux membres']
          }
          ,
          price: {
            af: '200',
            can: '300'
          },
          link: {
            af: '#',
            can: '#'
          }
        }
      ]    
    },
    {
      type: 'ENTREPRISES',
      detail: [
        {
          title: 'Startup',
          descrip:
          {
            libelle: 'Pour les nouvelles entreprises en activité depuis moins de deux ans',
            option: [
              'Réconnaissance lors de nos activités ou de nos communications',
              'Exclusivité pour les événements réservés aux partenaires-clients',
              'Participation à un podcast / Webinaire',
              'Visibilité du logo lors de nos événements à la hauteur du prix membre.'
            ]
          }
          ,
          price: {
            af: '150',
            can: '500'
          },
          link: {
            af: '#',
            can: '#'
          }
        },
        {
          title: 'Travailleur autonome',
          descrip:
          {
            libelle: 'Pour les entreprises ayant entre 1 à 4 employés',
            option: [
              'Réconnaissance lors de nos activités ou de nos communications',
              'Exclusivité pour les événements réservés aux partenaires-clients',
              'Participation à un podcast et/ou webinaire',
              'Visibilité du logo lors de nos événements à la hauteur du prix membre.'
            ]
          }
          ,
          price: {
            af: '100',
            can: '400'
          },
          link: {
            af: '#',
            can: '#'
          }
        },
        {
          title: 'Entreprise 5 à 49 employés',
          descrip:
          {
            libelle: '',
            option: [
              'Réconnaissance lors de nos activités ou de nos communications',
              'Exclusivité pour les événements réservés aux partenaires-clients',
              'Jusqu’à 5 employés inclus dans l’adhésion',
              'Participation à un podcast et/ou webinaire',
              'Visibilité du logo lors de nos événements à la hauteur du prix membre'
            ]
          }
          ,
          price: {
            af: '300',
            can: '800'
          },
          link: {
            af: '#',
            can: '#'
          }
        },
        {
          title: 'Entreprise 50 employés et +',
          descrip:
          {
            libelle: 'Pour les entreprises ayant plus de 51 employés',
            option: [
              'Prix partenaire-client lors des événements publics',
              ' Exclusivité pour les événements réservés aux partenaires-clients',
              'Jusqu’à 10 employés inclus dans l’adhésion',
              'Participation à un podcast et/ou webinaire',
              'Visibilité du logo lors de nos événements à la hauteur du prix membre.'
            ]
          }
          ,
          price: {
            af: '900',
            can: '1500'
          },
          link: {
            af: '#',
            can: '#'
          }
        }
      ]
    },
    {
      type: 'OBNL / INSTITUTS',
      detail: [
        {
          title: 'Organisme',
          descrip:
          {
            libelle: '',
            option: ['Prix partenaire-client lors des événements publics', 'Exclusivité pour les événements réservés aux partenaires-clients', 'Participation à un podcast','Nombre maximum: Illimité']
          }
          ,
          price: {
            af: '150',
            can: '250'
          },
          link: {
            af: '#',
            can: '#'
          }
        },
        {
          title: 'Politique',
          descrip:
          {
            libelle: 'Pour les personnes politiques désirant rencontrer la communauté d’affaire en Afrique et au Canada',
            option: ['Prix partenaire-client lors des événements publics', 'Exclusivité pour les événements réservés aux partenaires-clients', 'Pour une personne']
          }
          ,
          price: {
            af: '125',
            can: '450'
          },
          link: {
            af: '#',
            can: '#'
          }
        }
      ]
    },
    {
      type: 'PARTENAIRES PRESTIGES',
      detail: [
        {
          title: 'Répresentant',
          descrip:
          {
            libelle: 'Pour profiter de visibilité marketing',
            option: ['Prix partenaire-client lors des événements publics', 'Exclusivité pour les événements réservés aux partenaires-clients', 'Jusqu’à 3 employés inclus dans l’adhésion','Participation à au moins 2 podcasts / Webinaire chaque année']
          }
          ,
          price: {
            af: 'Gratuit',
            can: 'Gratuit'
          },
          link: {
            af: '#',
            can: '#'
          }
        },
        {
          title: 'Exclusif',
          descrip:
          {
            libelle: 'Un seul partenaire par expertise : « Partenaire mention du service officiel de la CCCA »',
            option: ['Aucune limite d’employés inscrits', 'Prix partenaire-client lors des événements publics', 'Exclusivité pour les événements réservés aux partenaires-clients', 'Jusqu’à 2 employés inclus dans l’adhésion','Participation à au moins 1 podcast / Webinaire par an.']
          }
          ,
          price: {
            af: '1000',
            can: '5000'
          },
          link: {
            af: '#',
            can: '#'
          }
        },
        {
          title: 'Ambassadeur',
          descrip:
          {
            libelle: '',
            option: ['Nombre maximum: Illimité (participation obligatoire à un comité).','Participation gratuite aux activités de la chambre']
          }
          ,
          price: {
            af: 'Gratuit',
            can: 'Gratuit'
          },
          link: {
            af: '#',
            can: '#'
          }
        },
      ]
    }
  ]
  const Tableau = ({item}) => {
    return (
      <div className='flex border-[1px] border-primary mb-7'>
        <div className='p-2 w-[20%] flex items-center justify-center border-r-[1px] border-primary'>{item.title}</div>
        <div className='p-3 w-[40%] flex flex-col items-center justify-center border-r-[1px] border-primary'>
          <h6>{item?.descrip?.libelle}</h6>
          <ul className='list-disc ps-4'>
            {item?.descrip?.option?.map((x,indexe)=>
              <li key={indexe}>{x}</li>
            )}
          </ul>
        </div>
        <div className='flex flex-col w-[15%] border-r-[1px] border-primary'>
          <div className='p-3 h-1/2 flex items-center justify-center'>Canada</div>
          <div className='p-3 h-1/2 flex items-center justify-center border-t-[1px] border-primary'>Afrique</div>
        </div>
        <div className='flex flex-col w-[15%] border-r-[1px] border-primary'>
          <div className='p-3 h-1/2 flex items-center justify-center'>{item.price.af} {item.price.af !== 'Gratuit' ? '$ CAD' : ''}</div>
          <div className='p-3 h-1/2 flex items-center justify-center border-t-[1px] border-primary'>{item.price.can} {item.price.can !== 'Gratuit' ? '$ CAD' : ''}</div>
        </div>
        <div className='w-[10%] text-green-600'>
          <div className='p-3 h-1/2 flex items-center justify-center'> souscrire</div>
          <div className='p-3 h-1/2 flex items-center justify-center border-t-[1px] border-primary'> souscrire</div>
        </div>
      </div>
    )
  }
  return (
    <Annimated>
      <Dialog open={opens} onClose={setOpens} className="relative z-[100]">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 w-screen overflow-y-auto z-100">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              {loading &&
                <div role="status" className="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2">
                  <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                  <span className="sr-only">Loading...</span>
                </div>
              }
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="w-full mt-3 text-center sm:mt-0">
                    <DialogTitle as="h1" className="text-base font-semibold leading-6 text-gray-900">
                      Formulaire d'adhesion
                    </DialogTitle>
                    <div className='flex flex-col gap-4 mt-2'>
                      {/* <div className='flex items-center gap-2'>Formation:
                                                <span className='text-secondary font-semibold text-center leading-5 text-[.85rem]'>{choix}</span></div> */}

                      <div className="flex flex-col w-full gap-3">
                        <input className='formulaire' ref={nom} type='text' placeholder='Nom et Prenom' />
                        <input className='formulaire' ref={mail} type='email' placeholder='Adresse Mail' />
                        <input className='formulaire' ref={progress} type='number' placeholder='Valeur comprise entre 0 et 100' />
                        <select className='formulaire' ref={stat}>
                          <option>Selectionner votre status</option>
                          <option value={'Particulier'}>Particulier</option>
                          <option value={'Entreprise'}>Entreprise</option>
                        </select>
                        <input className='formulaire' ref={nameE} type='text' placeholder='Nom de Votre Entreprise' />
                        <select className='formulaire' onChange={(e) => setProgs(e.target.value)}>
                          <option>Selectionner un programme</option>
                          {programme?.map((x, index) =>
                            <option key={index} value={x.id}>{x.titre}</option>
                          )}
                        </select>
                        {progs === '1' &&
                          <>
                            <select className='formulaire' value={ecole} onChange={(e) => setEcole(e.target.value)}>
                              <option>Selectionner une école</option>
                              {ecoles?.map((x, index) =>
                                <option key={index} value={x.titre}>{x.titre}</option>
                              )}
                            </select>
                            <select className='formulaire' value={forma} onChange={(e) => setForma(e.target.value)}>
                              <option>Selectionner une formation</option>
                              {sous_form?.find((y) => y.ecol === ecole)?.detail?.map((x, indexs) =>
                                <option key={indexs} value={x.titre}>{x.titre}</option>
                              )}
                            </select>
                          </>
                        }

                        <textarea className='formulaire' ref={message} rows={4} placeholder='Description'></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  onClick={() => choisirCanada()}
                  className="inline-flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-green-600 rounded-md shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Soumettre
                </button>
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpens(false)}
                  className="inline-flex justify-center w-full px-3 py-2 mt-3 text-sm font-semibold text-gray-900 bg-white rounded-md shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
          <Banner title='Dévenir Membre' />
           <div className='px-[5%] w-full flex gap-[4rem] py-14 max-md:py-12 '>
              <div className='w-[75%] max-md:w-full flex flex-col gap-12 max-md:px-0'>
                <div className='flex flex-col gap-2'>
                  <img className='w-full h-[150px] object-cover' src={bannersMembre} alt="#" />
                  {/* <button onClick={()=>setOpens(true)} className='py-3 w-1/3 max-md:w-full px-6 bg-secondary text-white'>Devenir Membre</button> */}
                </div>
                <div className='flex flex-col'>
                  <h1 className='font-bold uppercase text-[1.3rem] text-secondary py-4 border-b-2 border-secondary'>Choisir votre Catégorie de Memberchip pour La CCCA</h1>
                  <div className='flex flex-col w-full'>
                    {tables?.map((x)=>
                    <div className='flex flex-col overflow-hidden border-b-2 border-secondary'>
                        <div className='settings-btn cursor-pointer py-4 w-full text-[1.1rem] font-semibold flex justify-between items-center uppercase px-4 bg-gray-100 text-primary'>
                          <span>{x.type}</span>
                          <ArrowRight01Icon className='font-bold transition-all duration-500' />
                        </div>
                        <div className='transition-all duration-500' style={{ height: 0 }}>
                          {x?.detail?.map((y,ind) =>
                            <Tableau key={ind} item={y} />
                          )}
                        </div>
                      </div>
                    )}
                    
                  </div>
                </div>
                <div className='flex flex-col gap-5'>
                  <h1 className='text-[1.3rem] font-bold'>La Chambre de Commerce Canada Afrique (CCCA), c’est :</h1>
                  <div className='grid grid-cols-4 gap-8 max-md:gap-3'>
                    {state?.map((x,index)=>
                    <div key={index} className='flex flex-col items-center rounded-sm justify-center gap-1 p-6 bg-primary text-white'>
                      <span className='font-bold text-[1.5rem]'>{x.num}</span>
                      <p className='font-medium text-[.9rem]'>{x.libele}</p>
                    </div>
                    )}
                  </div>
                </div>
                <div className='flex flex-col gap-5'>
                  <h1 className='text-[1.3rem] font-bold'>Pourquoi devénir membre de la chambre?</h1>
                  <div className='grid grid-cols-2 max-md:grid-cols-1 gap-10'>
                    {membre?.map((x,index)=>
                    <div key={index} className='flex flex-col rounded-sm border-2 gap-5 py-8 px-8 border-primary p-6'>
                      <h1 className='font-bold underline  text-primary text-[1.5rem]'>{x.title}</h1>
                      <ul className='list-disc ps-[1rem] text-[.95rem]'>
                        {x.option?.map((y,ind)=>
                          <li key={ind}>{y}</li>
                        )}
                      </ul>
                    </div>
                    )}
                  </div>
                </div>
                <div>
                  <h1 className='text-[1.3rem] font-bold'>Témoignages des membres</h1>
                  
                </div>
              </div>
              <div className='w-[25%] flex flex-col gap-10 max-md:hidden'>
                  <div className='flex flex-col gap-3'>
                      <h5 className='uppercase text-[0.7rem] mb-0 font-light'>Pour plus d'informations</h5>
                      <div className='bg-primary px-8 flex flex-col gap-2 py-[1rem]'>
                          <span className='text-white'>Téléphone</span>
                          <div className='font-semibold bg-gray-100 px-4 py-3 rounded-sm flex item-center gap-3 text-[1rem]'>
                            <TelephoneIcon />
                            +1 (438) 388-3606
                          </div>
                      </div>
                      <div className='bg-primary px-8 flex flex-col gap-2 py-[1rem]'>
                          <span className='text-white'>Couriel</span>
                          <div className='font-semibold bg-gray-100 px-4 py-3 rounded-sm flex item-center gap-3 text-[1rem]'>
                            <Mail01Icon />
                           info@cc-ca.ca
                          </div>
                      </div>
                  </div>
                  <div className='flex flex-col gap-3'>
                      <h5 className='uppercase text-[0.7rem] mb-0 font-light'>Nos Parténaires</h5>
                      
                      <div className='bg-gray-100 px-8 py-5'>
                      <div className='flex items-center gap-3'>
                        <Swiper
                          modules={[Scrollbar, A11y, Autoplay]}
                          autoplay={{
                            delay: 4000,
                            disableOnInteraction: false,
                          }}
                          spaceBetween={15}
                          slidesPerView={1}
                        // effect='fade'
                        >
                          {data?.map((x) =>
                            <SwiperSlide className='bg-gray-100'>
                              <NavLink className='bg-gray-100 py-1 px-2 flex items-center justify-center rounded-sm' to={x.link}>
                                <img className=" h-[65px]" src={x.image} alt='' />
                              </NavLink>
                            </SwiperSlide>
                          )}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                  <Infolettre />
              </div>
          </div>
    </Annimated>
  )
}
